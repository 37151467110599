<template>
  <div>
    <base-card v-if="item == 6">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">Dining Option</span></div>
          <v-row>
            <!-- <v-col cols="12" lg="6"
              ><v-btn
                small
                color="primary"
                @click="(title = 'Add'), openDialog()"
                dark
              >
                Add Dining Option
              </v-btn></v-col
            > -->

            <v-switch v-model="switch1" @change="setDineInOption" flat>
              <template v-slot:label>
                <div>
                  Dine In
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon dense v-on="on">mdi-information</v-icon>
                    </template>
                    Enable or disable dine in option
                  </v-tooltip>
                </div>
              </template>
            </v-switch>
            <v-spacer></v-spacer>
            <v-col cols="12" lg="6"
              ><v-text-field
                class="mt-n5"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="diningList"
            :search="search"
          
            @click:row="handleClick"
          >
          </v-data-table>
        </v-card-text>
      </v-card-text>
    </base-card>
    <v-dialog v-model="dialog" width="400">
      <base-card>
        <v-card-text class="mt-3">
          <div class="mb-5">
            <span class="text-h6">{{ title }} Dining Option</span>
          </div>
          <v-text-field
            prepend-icon="mdi-silverware-variant"
            label="Option Name"
            v-model="oName"
            :error-messages="oNameErrors"
            required
            @input="$v.oName.$touch()"
            @blur="$v.oName.$touch()"
            disabled
          ></v-text-field>
          <v-select
            v-model="selectBranch"
            :items="branchList"
            multiple
            chips
            prepend-icon="mdi-store"
            item-text="name"
            item-value="branch_id"
            label="Select Branch"
            :error-messages="selectBranchErrors"
            required
            @input="$v.selectBranch.$touch()"
            @blur="$v.selectBranch.$touch()"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggleForBranch">
                <v-list-item-action>
                  <v-icon :color="selectBranch.length > 0 ? 'primary' : ''">
                    {{ iconForBranch }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- <v-btn
            v-if="title == 'Edit'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAction()" text> save </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deleteDiningOption" text
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    item: {
      type: Number,
      default: "",
    },
  },
  mixins: [validationMixin],

  validations: {
    oName: { required },
    selectBranch: { required },
  },

  data: () => ({
    domain: BASEURL,
    syncDomain: BASESYNCURL,
    snackbar: false,
    text: "",
    snackbarColor: "",
    timeout: 1900,
    diningList: [],
    companyID: "",
    branchList: [],
    oName: "",
    selectBranch: "",
    selectDiningID: "",
    title: "",
    dialog: false,
    search: "",
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
    ],
    switch1: null,
  }),
  watch: {
    // switch2() {
    //   if (this.switch1) {
    //     console.log("yes")
    //     // this.setDineInOption();
    //     // this.getAllDiningOption();
    //     // for (let i = 0; i < this.selectBranch.length; i++) {
    //     //   this.sendNotication(this.selectBranch[i]);
    //     // }
    //   } else {
    //     console.log("no")
    //     // this.setDineInOption();
    //     // this.getAllDiningOption();
    //     // for (let i = 0; i < this.selectBranch.length; i++) {
    //     //   this.sendNotication(this.selectBranch[i]);
    //     // }
    //   }
    // },
  },

  computed: {
    oNameErrors() {
      const errors = [];
      if (!this.$v.oName.$dirty) return errors;
      !this.$v.oName.required && errors.push("Dining option name is required");
      return errors;
    },
    selectBranchErrors() {
      const errors = [];
      if (!this.$v.selectBranch.$dirty) return errors;
      !this.$v.selectBranch.required &&
        errors.push("Please select the branch for the dining option");
      return errors;
    },
    getAllBranchID() {
      var array = [];
      for (let i = 0; i < this.branchList.length; i++) {
        array.push(this.branchList[i].branch_id);
      }
      return array;
    },

    selectAllBranch() {
      return this.selectBranch.length === this.getAllBranchID.length;
    },

    selectSomeBranch() {
      return this.selectBranch.length > 0 && !this.selectAllBranch;
    },

    iconForBranch() {
      if (this.selectAllBranch) return "mdi-close-box";
      if (this.selectSomeBranch) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllDiningOption();
    this.getCompanyBranch();
  },
  methods: {
    getAllDiningOption() {
      const params = new URLSearchParams();
      params.append("getAllDiningOption", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/dining/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.diningList = response.data.dining;
            this.setSwitch()
            
          } else {
            console.log("no list get");
            this.diningList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
            this.branchList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleForBranch() {
      this.$nextTick(() => {
        if (this.selectAllBranch) {
          this.selectBranch = [];
        } else {
          this.selectBranch = this.getAllBranchID.slice();
        }
      });
    },

    setSwitch() {
      for (let i = 0; i < this.diningList.length; i++) {
        if (this.diningList[i].name == "Dine in") {
          this.switch1 = true;
        }
      }
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setDineInOption() {
      const params = new URLSearchParams();
      params.append("setDineIn", "done");
      params.append("switch", this.switch1 ? "enable" : "disable");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/dining/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            for (let i = 0; i < this.branchList.length; i++) {
              this.sendNotication(this.branchList[i].branch_id);
            }
            this.getAllDiningOption();

          } else {
            this.text = "Please try again later";
            this.snackbar = true;
            this.snackbarColor = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteDiningOption() {
      const params = new URLSearchParams();
      params.append("deleteDiningOption", "done");
      params.append("dining_id", this.selectDiningID);
      axios({
        method: "post",
        url: this.domain + "/dining/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.dialog = false;
            this.text = "Successfully delete";
            this.snackbar = true;
            this.snackbarColor = "success";
            this.dialogDelete = false;
            this.getAllDiningOption();
          } else {
            this.text = "Please try again later";
            this.snackbar = true;
            this.snackbarColor = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogAction() {
      if (this.title == "Add") {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("addDiningOption", "done");
          params.append("company_id", this.companyID);
          params.append("array_branch_id", JSON.stringify(this.selectBranch));
          params.append("name", this.oName);

          axios({
            method: "post",
            url: this.domain + "/dining/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully add";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllDiningOption();
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("editDiningOption", "done");
          params.append("dining_id", this.selectDiningID);
          params.append("array_branch_id", JSON.stringify(this.selectBranch));
          params.append("name", this.oName);
          axios({
            method: "post",
            url: this.domain + "/dining/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllDiningOption();
                for (let i = 0; i < this.selectBranch.length; i++) {
                  this.sendNotication(this.selectBranch[i]);
                }
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    handleClick(item) {
      this.oName = item.name;
      this.selectDiningID = item.dining_id;
      this.getDiningOptionBranch();
      this.title = "Edit";
      this.openDialog();
    },

    getDiningOptionBranch() {
      const params = new URLSearchParams();
      params.append("getDiningOptionBranch", "done");
      params.append("dining_id", this.selectDiningID);

      axios({
        method: "post",
        url: this.domain + "/dining/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.selectBranch = response.data.dining;
          } else {
            console.log("no list get");
            this.branchList = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openDialog() {
      
      if (this.title == "Add") {
        this.$v.$reset();
        this.oName = "";
        this.selectBranch = "";
        this.dialog = true;
      } else {
        this.$v.$reset();
        this.dialog = true;
      }
    },
  },
};
</script>
