<template>
  <div>
    <base-card v-if="item == 1">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">Current Subscription</span></div>
          <v-row v-if="subscriptionList != ''">
            <v-col cols="12" md="5">
              <span class="text-decoration-underline">{{
                subscriptionList[0].subscribe_package
              }}</span>
              <br />
              - This package is valid for
              {{ subscriptionList[0].duration }} months
              <br />
              - Include {{ subscriptionList[0].branch_amount }} Branch
            </v-col>
            <v-col cols="12" md="4"
              >
              
              <!-- <span class="font-weight-black"
                >RM{{ subscriptionList[0].subscribe_fee }}</span
              > -->
              
              </v-col
            >
            <v-col cols="12" md="3"
              >Expire on {{ subscriptionList[0].end_date }}</v-col
            >
          </v-row>
          <v-row v-else-if="subscriptionList == ''">
            <v-card-text class="text-center">
              <div class="pa-16">
                <v-btn
                  class="mx-2"
                  fab
                  x-large
                  disabled
                  depressed
                  style="font-size: 399px !important"
                >
                  <v-icon x-large> mdi-file-sign </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <div class="my-2">
                  <span class="text-subtitle-1">No Subscription</span>
                </div>
                <v-spacer></v-spacer>
              </div>
            </v-card-text>
          </v-row>
        </v-card-text>
      </v-card-text>
    </base-card>


    

    <base-card v-if="item == 1" class="mt-5">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">Billing History</span></div>
          <v-row v-if="subscriptionList != ''">
            <v-data-table
              :headers="headers"
              :items="billHistoryList"
              :items-per-page="itemsPerPage"
            >
            </v-data-table>
          </v-row>
          <v-row v-else-if="subscriptionList == ''">
            <v-card-text class="text-center">
              <div class="pa-16">
                <v-btn
                  class="mx-2"
                  fab
                  x-large
                  disabled
                  depressed
                  style="font-size: 399px !important"
                >
                  <v-icon x-large> mdi-file-sign </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <div class="my-2">
                  <span class="text-subtitle-1">No Bill History</span>
                </div>
                <v-spacer></v-spacer>
              </div>
            </v-card-text>
          </v-row>
        </v-card-text>
      </v-card-text>
    </base-card>
  </div>
</template>
<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";

export default {
  props: {
    item: {
      type: Number,
      default: "",
    },
  },
  data: () => ({
    domain: BASEURL,
    companyID: "",
    subscriptionList: "",
    headers: [
      {
        text: "Package Name",
        align: "start",
        sortable: false,
        value: "subscribe_package",
      },
      // { text: "Fee",align: "start", value: "subscribe_fee" },
      { text: "Duration(month)",align: "start", value: "duration" },
      { text: "Start Date", align: "start",value: "start_date" },
      { text: "End Date",align: "start", value: "end_date" },
      { text: "Bill At", align: "start",value: "created_at" },
    ],
    billHistoryList:"",
    itemsPerPage: 10
  }),

  computed: {},

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllSubscription();
    this.getBillHistory()
    
  },
  methods: {
    getAllSubscription() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/subscription/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.subscriptionList = response.data.subscription;
            console.log(this.subscriptionList);
          } else {
            this.subscriptionList = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBillHistory() {
      const params = new URLSearchParams();
      params.append("getBillHistory", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/subscription/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.billHistoryList = response.data.subscription;
            console.log(this.billHistoryList);
          } else {
            this.billHistoryList = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
