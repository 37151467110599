<template>
  <v-row>
    <v-col cols="12" lg="3">
      <base-card>
        <v-list flat>
          <v-list-item-group v-model="item" mandatory color="primary">
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-list-item-icon>
                <v-icon v-text="item.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </base-card>
    </v-col>
    <v-col cols="12" lg="9"> 
        <account-card :item="item"> </account-card>
        <subcription-card :item="item"> </subcription-card>
        <branch-card :item="item"> </branch-card>
        <pos-card :item="item"> </pos-card>
        <payment-card :item="item"> </payment-card>
        <tax-card :item="item"> </tax-card>
        <dining-card :item="item"> </dining-card>
        <table-card :item="item"> </table-card>
        <promotion-card :item="item"> </promotion-card>
        <second-screen-card :item="item"> </second-screen-card>
    </v-col>
  </v-row>
</template>
<script>
import AccountCard from "@/components/setting/AccountCard";
import SubcriptionCard from "@/components/setting/SubcriptionCard";
import BranchCard from "@/components/setting/BranchCard";
import PosCard from "@/components/setting/PosCard";
import PaymentCard from "@/components/setting/PaymentCard";
import DiningCard from "@/components/setting/DiningCard";
import TaxCard from "@/components/setting/TaxCard";
import TableCard from "@/components/setting/TableCard";
import PromotionCard from "@/components/setting/PromotionCard";
import SecondScreenCard from "@/components/setting/SecondScreenCard";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Setting",
  },

  components: {
    AccountCard,
    SubcriptionCard,
    BranchCard,
    PosCard,
    PaymentCard,
    DiningCard,
    TaxCard,
    PromotionCard,
    TableCard,
    SecondScreenCard
    
  },

  data: () => ({
    item: 0,
    items: [
      { text: "Account", icon: "mdi-google-my-business" },
      { text: "Subscription", icon: "mdi-wallet-membership" },
      { text: "Branch", icon: "mdi-license" },
      { text: "POS Device", icon: "mdi-devices" },
      { text: "Payment Type", icon: "mdi-cash-multiple" },
      { text: "Tax", icon: "mdi-receipt" },
      { text: "Dining Option", icon: "mdi-silverware" },
      { text: "Restaurant Table", icon: "mdi-table-chair" },
      { text: "Promotion", icon: "mdi-sale" },
      { text: "Second Screen", icon: "mdi-monitor-screenshot" },
    ],
  }),
};
</script>

