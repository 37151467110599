<template>
  <div>
    <base-card v-if="item == 3">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">POS</span></div>
          <v-row>
            <!-- <v-col cols="12" lg="6"
              ><v-btn
                small
                color="primary"
                @click="(title = 'Add'), openDialog()"
                dark
              >
                Add POS
              </v-btn></v-col
            > -->
            <v-col cols="12"
              ><v-text-field
                class="mt-n5"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="posList"
            :search="search"
       
            @click:row="handleClick"
          >
            <template v-slot:item.status="{ item }">
              <span v-if="item.status == 0" class="text-body-2"
                >Not Activate</span
              >
              <span v-else class="text-body-2">Activate</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card-text>
    </base-card>
    <v-dialog v-model="dialog" width="400">
      <base-card>
        <v-card-text class="mt-3">
          <div class="mb-5">
            <span class="text-h6">{{ title }} POS</span>
          </div>
          <v-text-field
            prepend-icon="mdi-printer-pos"
            label="POS Name"
            v-model="pName"
            :error-messages="pNameErrors"
            required
            @input="$v.pName.$touch()"
            @blur="$v.pName.$touch()"
          ></v-text-field>
          <v-select
            v-model="selectBranch"
            :items="branchList"
            prepend-icon="mdi-store"
            item-text="name"
            item-value="branch_id"
            label="Select Branch"
            :error-messages="selectBranchErrors"
            required
            @input="$v.selectBranch.$touch()"
            @blur="$v.selectBranch.$touch()"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="title == 'Edit'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAction()" text> save </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deletePOS" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    item: {
      type: Number,
      default: "",
    },
  },
  mixins: [validationMixin],

  validations: {
    pName: { required },
    selectBranch: { required },
  },

  data: () => ({
    domain: BASEURL,
    snackbar: false,
    text: "",
    snackbarColor: "",
    timeout: 1900,
    title: "",
    dialog: false,
    companyID: "",
    search: "",
    posList: [],
    pName: "",
    branchList: [],
    selectBranch: "",
    selectDevice: "",
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
      { text: "Branch", value: "branch", sortable: false },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
    ],
  }),

  computed: {
    pNameErrors() {
      const errors = [];
      if (!this.$v.pName.$dirty) return errors;
      !this.$v.pName.required && errors.push("POS name is required");
      return errors;
    },
    selectBranchErrors() {
      const errors = [];
      if (!this.$v.selectBranch.$dirty) return errors;
      !this.$v.selectBranch.required &&
        errors.push("Please select the branch for the POS");
      return errors;
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllPos();
  },

  mounted() {
    this.$root.$on("component2", () => {
      this.getAllPos();
    });
  },

  methods: {
    getAllPos() {
      const params = new URLSearchParams();
      params.append("getAllPos", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/device/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.posList = response.data.device;
          } else {
            console.log("no list get");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deletePOS() {
      const params = new URLSearchParams();
      params.append("delete", "done");
      params.append("device_id", this.selectDevice);
      axios({
        method: "post",
        url: this.domain + "/device/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.dialog = false;
            this.text = "Successfully delete";
            this.snackbar = true;
            this.snackbarColor = "success";
            this.dialogDelete = false;
            this.$root.$emit("component1"); //refresh branch card
            this.getAllPos();
          } else {
            this.text = "Please try again later";
            this.snackbar = true;
            this.snackbarColor = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogAction() {
      if (this.title == "Add") {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("addPOS", "done");
          params.append("company_id", this.companyID);
          params.append("branch_id", this.selectBranch);
          params.append("name", this.pName);

          axios({
            method: "post",
            url: this.domain + "/device/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully add";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.$root.$emit("component1"); //refresh branch card
                this.getAllPos();
              } else if (response.data.status == "2") {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              } else if (response.data.status == "3") {
                this.text = "Your selected branch has reach 1 POS device limit";
                this.snackbar = true;
                this.snackbarColor = "warning";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("editPOS", "done");
          params.append("device_id", this.selectDevice);
          params.append("name", this.pName);
          params.append("branch_id", this.selectBranch);
          axios({
            method: "post",
            url: this.domain + "/device/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.$root.$emit("component1"); //refresh branch card
                this.getAllPos();
              } else if (response.data.status == "2") {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              } else if (response.data.status == "3") {
                this.text = "Your selected branch has reach 1 POS device limit";
                this.snackbar = true;
                this.snackbarColor = "warning";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    handleClick(item) {
      this.pName = item.name;
      this.selectDevice = item.device_id;
      this.selectBranch = parseInt(item.branch_id);
      this.title = "Edit";
      this.openDialog();
    },

    openDialog() {
      this.getCompanyBranch();
      if (this.title == "Add") {
        this.$v.$reset();
        this.pName = "";
        this.selectBranch = "";
        this.dialog = true;
      } else {
        this.$v.$reset();
        this.dialog = true;
      }
    },
  },
};
</script>
