<template>
  <div>
    <base-card v-if="item == 9">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">Second Screen</span></div>
          <v-card-text>
            <v-row
              ><span class="mb-3"
                >Please choose the branch for uploading banner</span
              ></v-row
            >
            <v-row class="mb-10">
              <v-col cols="6">
                <v-select
                  v-model="selectedBranch"
                  :items="branchList"
                  item-text="name"
                  item-value="branch_id"
                  label="Choose Branch"
                  solo
                  :menu-props="{ maxHeight: 120 }"
                  @change="(fileRecords = []), getImageBanner()"
                ></v-select
              ></v-col>
            </v-row>

            <div v-if="selectedBranch">
              <v-row
                ><span class="mb-3"
                  >Please upload your second screen banner here. If you facing issue on multiple upload please kindly upload one image at one time and click save (recommend
                  size: 1280x800)</span
                ></v-row
              >
              <v-row>
                <v-col cols="12">
                  <VueFileAgent
                    ref="vueFileAgent"
                    :theme="'list'"
                    :multiple="true"
                    :deletable="true"
                    :meta="true"
                    :accept="'image/*'"
                    :maxSize="'1MB'"
                    :maxFiles="5"
                    :helpText="'Choose files or drag & drop here'"
                    :errorText="{
                      type: 'Invalid file type. Only images Allowed',
                      size: 'Files should not exceed 1MB in size',
                    }"
                    @select="filesSelected($event)"
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)"
                    v-model="fileRecords"
                  ></VueFileAgent>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-btn
                  :disabled="fileRecordsForUpload.length == 0 || errorFound != 0"
                  @click="(loading = true), uploadFiles()"
                  depressed
                  :loading="loading"
                  color="primary"
                >
                  save
                </v-btn>
              </v-row>
            </div>
          </v-card-text>
        </v-card-text>
      </v-card-text>
    </base-card>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
  <script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";

export default {
  props: {
    item: {
      type: Number,
      default: "",
    },
  },
  components: {},
  data: () => ({
    domain: BASEURL,
    syncDomain: BASESYNCURL,
    companyID: "",
    snackbar: false,
    text: "",
    loading: false,
    snackbarColor: "",
    timeout: 1900,
    branchList: [],
    selectedBranch: "",
    fileRecords: [],
    fileRecordsForUpload: [], // maintain an upload queue
    errorFound: 0,
  }),
  watch: {
    fileRecords() {
      for (let i = 0; i < this.fileRecords.length; i++) {
        if (
          this.fileRecords[i].error.size == true ||
          this.fileRecords[i].error.type == true
        ) {
          this.errorFound++;
        }
      }
    },
    selectedBranch() {
      this.fileRecordsForUpload= []
    },
  },

  computed: {},

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getCompanyBranch();
    this.selectedBranch ="";
    this.$forceUpdate();
    
  },
  methods: {
    images(e) {
      e.map((res) => console.log(res));
    },
    clear() {
      this.component.clear = true;
    },
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getImageSizeInBytes(imgURL) {
      var request = new XMLHttpRequest();
      request.open("HEAD", imgURL, false);
      request.send(null);
      var headerText = request.getAllResponseHeaders();
      var re = /Content\-Length\s*:\s*(\d+)/i;
      re.exec(headerText);
      return parseInt(RegExp.$1);
    },

    getImageBanner() {
      const params = new URLSearchParams();
      params.append("getImageBanner", "done");
      params.append("company_id", this.companyID);
      params.append("branch_id", this.selectedBranch);

      axios({
        method: "post",
        url: this.domain + "/second_screen/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var bannerList = response.data.second_screen;
            for (let i = 0; i < bannerList.length; i++) {
              var tempObj = {};
              const myArray = bannerList[i].name.split(".");
              tempObj.name = bannerList[i].name;

              tempObj.url =
                this.domain +
                "banner/" +
                this.companyID +
                "/" +
                this.selectedBranch +
                "/" +
                bannerList[i].name;
              const size = this.getImageSizeInBytes(
                this.domain +
                  "banner/" +
                  this.companyID +
                  "/" +
                  this.selectedBranch +
                  "/" +
                  bannerList[i].name
              );

              tempObj.size = size;
              tempObj.error = {};
              tempObj.raw = "";
              tempObj.filename = bannerList[i].name;
              tempObj.error.size = false;
              tempObj.error.type = false;
              tempObj.type = "image/" + myArray[1];
              this.fileRecords.push(tempObj);
              this.fileRecordsForUpload.push(tempObj);
            }
          } else {
            console.log("no branch banner get");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    uploadFiles: function () {
      const params = new URLSearchParams();
      params.append("uploadSecondScreenBanner", "done");
      params.append("company_id", this.companyID);
      params.append("branch_id", this.selectedBranch);
      params.append("file_list", JSON.stringify(this.fileRecordsForUpload));

      axios({
        method: "post",
        url: this.domain + "/second_screen/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.text = "Successfully upload";
            this.snackbar = true;
            this.snackbarColor = "success";
            this.loading = false;
            this.sendNotication(this.selectedBranch);
            this.selectedBranch=""
          } else {
            this.text = "Please try again later";
            this.snackbar = true;
            this.snackbarColor = "error";
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    isBlob(input) {
      if ("Blob" in window && input instanceof Blob) return true;
      else return false;
    },

    deleteUploadedFile: function (fileRecord) {},
    ////
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);

      for (let i = 0; i < this.fileRecordsForUpload.length; i++) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.fileRecordsForUpload[i].raw = reader.result;
          this.fileRecordsForUpload[i].filename = "";
        };
        if (this.isBlob(this.fileRecordsForUpload[i].file)) {
          reader.readAsDataURL(this.fileRecordsForUpload[i].file);
        } else {
          this.fileRecordsForUpload[i].raw = "";
        }
      }
    },
    onBeforeDelete: function (fileRecord) {
      this.errorFound = 0;
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        if (confirm("Are you sure you want to delete?")) {
          // queued file, not yet uploaded. Just remove from the arrays
          this.fileRecordsForUpload.splice(i, 1);
          var k = this.fileRecords.indexOf(fileRecord);
          if (k !== -1) this.fileRecords.splice(k, 1);
          console.log(this.fileRecordsForUpload)
        }
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
};
</script>
  