<template>
  <div>
    <base-card v-if="item == 4">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">Payment Type</span></div>
          <v-row>
            <v-col cols="12" lg="6"
              ><v-btn
                small
                color="primary"
                @click="(title = 'Add'), openDialog()"
                dark
              >
                Add Payment Type
              </v-btn></v-col
            >
            <v-col cols="12" lg="6"
              ><v-text-field
                class="mt-n5"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="paymentList"
            :search="search"
            @click:row="handleClick"
          >
          </v-data-table>
        </v-card-text>
      </v-card-text>
    </base-card>
    <v-dialog v-model="dialog" width="400">
      <base-card>
        <v-card-text class="mt-3">
          <div class="mb-5">
            <span class="text-h6">{{ title }} Payment Type</span>
          </div>
          <v-select
            v-model="selectPaymentType"
            :items="availablePaymentList"
            prepend-icon="mdi-cash-multiple"
            item-text="name"
            item-value="payment_type_id"
            label="Select Payment Type"
            @change="updateName(selectPaymentType), (imageAllow = 0)"
            :error-messages="selectPaymentTypeErrors"
            required
            @input="$v.selectPaymentType.$touch()"
            @blur="$v.selectPaymentType.$touch()"
          ></v-select>

          <!-- <v-text-field
            prepend-icon="mdi-cash"
            label="Payment Name"
            v-model="paymentName"
            :error-messages="paymentNameErrors"
            required
            @input="$v.paymentName.$touch()"
            @blur="$v.paymentName.$touch()"
          ></v-text-field> -->

          <v-checkbox
            v-if="
              selectPaymentType != '1' &&
              selectPaymentType != '2' &&
              selectPaymentType != ''
            "
            class="mt-n1"
            v-model="imageAllow"
            false-value="0"
            true-value="1"
            label="Set Image"
            @change="(fileRecords = []), (fileRecordsForUpload = [])"
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                The image will be display on second screen when payment select.
                (Recommend size: 500 x 500 px)
              </v-tooltip>
            </template>
          </v-checkbox>

          <div class="mt-n6" v-if="imageAllow == 1">
            <v-row>
              <v-col>
                <VueFileAgent
                  ref="vueFileAgent"
                  :theme="'list'"
                  :multiple="false"
                  :deletable="true"
                  :meta="true"
                  :accept="'image/*'"
                  :maxSize="'80KB'"
                  :maxFiles="1"
                  :helpText="'Choose files or drag & drop here'"
                  :errorText="{
                    type: 'Invalid file type. Only images Allowed',
                    size: 'Files should not exceed 80KB in size',
                  }"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                  v-model="fileRecords"
                ></VueFileAgent>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="title == 'Edit'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="checkLoading"
            @click="dialogAction(), (checkLoading = true)"
            text
          >
            save
          </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deletePayment" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    item: {
      type: Number,
      default: "",
    },
  },
  mixins: [validationMixin],

  validations: {
    paymentName: { required },
    selectPaymentType: { required },
  },

  data: () => ({
    domain: BASEURL,
    syncDomain: BASESYNCURL,
    snackbar: false,
    text: "",
    snackbarColor: "",
    timeout: 1900,
    paymentList: [],
    companyID: "",
    availablePaymentList: [],
    selectPaymentType: "",
    paymentName: "",
    selectCurrentPaymentType: "",
    title: "",
    imageAllow: "0",
    fileRecords: [],
    fileRecordsForUpload: [], // maintain an upload queue
    errorFound: 0,
    dialog: false,
    search: "",
    branchList: "",
    checkLoading: false,
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
    ],
  }),

  watch: {
    fileRecords() {
      for (let i = 0; i < this.fileRecords.length; i++) {
        if (
          this.fileRecords[i].error.size == true ||
          this.fileRecords[i].error.type == true
        ) {
          this.errorFound++;
        }
      }
    },
  },

  computed: {
    paymentNameErrors() {
      const errors = [];
      if (!this.$v.paymentName.$dirty) return errors;
      !this.$v.paymentName.required && errors.push("Payment name is required");
      return errors;
    },
    selectPaymentTypeErrors() {
      const errors = [];
      if (!this.$v.selectPaymentType.$dirty) return errors;
      !this.$v.selectPaymentType.required &&
        errors.push("Please select the payment type");
      return errors;
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllPaymentType();
    this.getCompanyBranch();
  },
  methods: {
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
            this.branchList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllPaymentType() {
      const params = new URLSearchParams();
      params.append("getAllPaymentType", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/payment/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.paymentList = response.data.payment;
          } else {
            console.log("no list get");
            this.paymentList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAvailablePayment() {
      const params = new URLSearchParams();
      params.append("getAvailablePayment", "done");
      axios({
        method: "post",
        url: this.domain + "/payment/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.availablePaymentList = response.data.payment;
          } else {
            console.log("no list get");
            this.availablePaymentList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateName(paymentTypeID) {
      for (let i = 0; i < this.availablePaymentList.length; i++) {
        if (this.availablePaymentList[i].payment_type_id == paymentTypeID) {
          this.paymentName = this.availablePaymentList[i].name;
        }
      }
    },

    deletePayment() {
      const params = new URLSearchParams();
      params.append("delete", "done");
      params.append("payment_link_company_id", this.selectCurrentPaymentType);
      axios({
        method: "post",
        url: this.domain + "/payment/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.dialog = false;
            this.text = "Successfully delete";
            this.snackbar = true;
            this.snackbarColor = "success";
            this.dialogDelete = false;
            this.getAllPaymentType();
            for (let i = 0; i < this.branchList.length; i++) {
              this.sendNotication(this.branchList[i].branch_id);
            }
          } else {
            this.text = "Please try again later";
            this.snackbar = true;
            this.snackbarColor = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogAction() {
      if (this.title == "Add") {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (this.imageAllow == 1 && this.fileRecordsForUpload.length == 0) {
            this.checkLoading = false;
            this.text = "Please upload an image and make sure image size do not exceed 80KB";
            this.snackbar = true;
            this.snackbarColor = "error";

            return;
          }
          const params = new URLSearchParams();
          params.append("addPaymentType", "done");
          params.append("company_id", this.companyID);
          params.append("payment_type_id", this.selectPaymentType);
          params.append("name", this.paymentName);
          params.append("allow_image", this.imageAllow);
          params.append(
            "image_file",
            JSON.stringify(this.fileRecordsForUpload)
          );

          axios({
            method: "post",
            url: this.domain + "/payment/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.checkLoading = false;
                this.dialog = false;
                this.text = "Successfully add";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllPaymentType();
                for (let i = 0; i < this.branchList.length; i++) {
                  this.sendNotication(this.branchList[i].branch_id);
                }
              } else {
                this.checkLoading = false;
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (this.imageAllow == 1 && this.fileRecordsForUpload.length == 0) {
            this.text = "Please upload an image and make sure image size do not exceed 80KB";
            this.snackbar = true;
            this.snackbarColor = "error";
            process.nextTick(() => {
              this.checkLoading = false;
            });

            return;
          }
          console.log(this.selectCurrentPaymentType);
          console.log(this.selectPaymentType);
          console.log(this.paymentName);
          console.log(this.imageAllow);
          console.log(this.fileRecordsForUpload);
          const params = new URLSearchParams();
          params.append("editPaymentType", "done");
          params.append(
            "payment_link_company_id",
            this.selectCurrentPaymentType
          );
          params.append("payment_type_id", this.selectPaymentType);
          params.append("name", this.paymentName);
          params.append("allow_image", this.imageAllow);
          params.append(
            "image_file",
            JSON.stringify(this.fileRecordsForUpload)
          );

          axios({
            method: "post",
            url: this.domain + "/payment/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.checkLoading = false;
                this.dialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllPaymentType();
                for (let i = 0; i < this.branchList.length; i++) {
                  this.sendNotication(this.branchList[i].branch_id);
                }
              } else {
                this.checkLoading = false;
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    handleClick(item) {
      this.fileRecords = [];
      this.fileRecordsForUpload = [];
      this.paymentName = item.name;
      this.selectPaymentType = parseInt(item.payment_type_id);
      this.selectCurrentPaymentType = item.payment_link_company_id;
      this.imageAllow = item.allow_image.toString();
      this.getImageBanner(item.image_name, item.payment_link_company_id);
      this.title = "Edit";
      this.openDialog();
    },

    getImageBanner(imgName, paymentID) {
      var tempObj = {};
      const imgExtension = imgName.split(".");
      tempObj.name = imgName;
      tempObj.url =
        this.domain +
        "payment_QR/" +
        this.companyID +
        "/" +
        paymentID +
        "/" +
        imgName;

      const size = this.getImageSizeInBytes(
        this.domain +
          "payment_QR/" +
          this.companyID +
          "/" +
          paymentID +
          "/" +
          imgName
      );

      tempObj.size = size;
      tempObj.error = {};
      tempObj.raw = "";
      tempObj.filename = imgName;
      tempObj.error.size = false;
      tempObj.error.type = false;
      tempObj.type = "image/" + imgExtension[1];
      this.fileRecords.push(tempObj);
      this.fileRecordsForUpload.push(tempObj);
    },

    openDialog() {
      this.getAvailablePayment();
      if (this.title == "Add") {
        this.$v.$reset();
        this.paymentName = "";
        this.selectPaymentType = "";
        this.imageAllow = "0";
        this.fileRecords = [];
        this.fileRecordsForUpload = [];
        this.dialog = true;
      } else {
        this.$v.$reset();
        this.dialog = true;
      }
    },

    // for image
    getImageSizeInBytes(imgURL) {
      var request = new XMLHttpRequest();
      request.open("HEAD", imgURL, false);
      request.send(null);
      var headerText = request.getAllResponseHeaders();
      var re = /Content\-Length\s*:\s*(\d+)/i;
      re.exec(headerText);
      return parseInt(RegExp.$1);
    },

    uploadFiles: function () {
      console.log(this.fileRecordsForUpload);
      console.log(this.imageAllow);
    },
    isBlob(input) {
      if ("Blob" in window && input instanceof Blob) return true;
      else return false;
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);

      for (let i = 0; i < this.fileRecordsForUpload.length; i++) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.fileRecordsForUpload[i].raw = reader.result;
          this.fileRecordsForUpload[i].filename = "";
        };
        if (this.isBlob(this.fileRecordsForUpload[i].file)) {
          reader.readAsDataURL(this.fileRecordsForUpload[i].file);
        } else {
          this.fileRecordsForUpload[i].raw = "";
        }
      }
    },
    onBeforeDelete: function (fileRecord) {
      this.errorFound = 0;
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        if (confirm("Are you sure you want to delete?")) {
          // queued file, not yet uploaded. Just remove from the arrays
          this.fileRecordsForUpload.splice(i, 1);
          var k = this.fileRecords.indexOf(fileRecord);
          if (k !== -1) this.fileRecords.splice(k, 1);
        }
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      }
    },
  },
};
</script>
